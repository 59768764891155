// background: linear-gradient(to bottom left, cyan 50%, palegoldenrod 50%);
@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.Exterior{@include sec-default();
  @include bp(big){max-width:1900px;@include center-block();}


  .midExt{margin-bottom:30px;
    &::after{@include after();left:3%;width:97%;height:65%; background-color:$color1-lt2;}
    @include bp(br){&::after{left:0;width:100%;}}
  }


    
  .extSlideWrap{@include sec-default();position:relative;z-index:0; margin-bottom:20px;//max-height:800px;overflow:hidden;
    .slideText{position:absolute;width:50%;max-width:500px;margin:3% 0 0 3%;z-index:2;}
    p{color:$white;font-weight:bold;font-size:27px;text-shadow:0px 1px 2px rgba($black,.5);font-family:$heading;line-height:1.4em;
      a{color:lighten($color1,10%);}
    }

    .maxHeight{max-height:800px;overflow:hidden;}
    .dotNav{width:100%;align-items:center;justify-content:center;margin-top:-30px;position:relative;z-index:2;
      a{width:12px;height:12px;border-color:$white;}
    }

    @include bp(tl){
      .slideText{max-width:460px;margin:25px 0 0 25px;}
      p{font-size:23px;}
    }

    @include bp(tw){//max-height:500px;
      .maxHeight{max-height:500px;}
      .slideText{max-width:420px;margin:25px 0 0 25px;}
      p{font-size:21px;}
    }

    @include bp(tb){
      .slideText{max-width:350px;margin:15px 0 0 15px;}
      p{font-size:19px;} 
    }
    
    @include bp(br){
      .slideText{width:100%;margin:0;max-width:100%;text-align:center;padding:15px 0;
        &::before{@include before();background:radial-gradient(rgba($black,.25) 20%, transparent 70%);width:80%;left:10%;top:-7%;}
      }
      p{font-size:17px;max-width:500px;@include center-block();margin-bottom:10px;text-shadow:0px 1px 2px rgba($black,.8);}
      p a{color:$white;}

      .dotNav{margin-top:-25px;}
      .ssImage::before{@include before();background-color:rgba($black,.2);z-index:2;}
    }

    @include bp(oc){
      .slideText{position:relative;padding:0;
        &::before{display:none;}
        p{color:$color1;text-shadow:none;}
        p a{color:$color2;}
      }
    }
  }


  .extraText{@include sec-default();margin:60px 0;
    span{color:$color1;}
  
    @include bp(tl){margin:40px 0 30px;}
    @include bp(tb){margin:30px 0 20px;}

    @include bp(br){p{font-size:16px;}}
    @include bp(oc){margin:20px 0 10px;
      span{color:$color2;width:50%;}
    }
  }

  .custMob{max-width:950px;
    @include bp(tb){max-width:830px;}
    @include bp(br){max-width:730px;}
    @include bp(md){max-width:650px;}
    @include bp(og){width:90%;max-width:100%;}
  }
  .scrollSec{@include sec-default();}
}