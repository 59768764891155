@import '../../assets/global.scss';
@import '../../assets/variables.scss';
@import '../../assets/mixins.scss';

.Social{display:inline-block;
  li {display:inline-block;margin-right:5px;
    &:last-child{margin-right:0;}
  }
  li,a{line-height:1em;}
}

.emailModal .modalClose{color:$black;}
.eModalBody{width:660px;
  h4{font-size:16px;color:$color1;font-weight:bold;font-family:$heading;border-bottom:1px solid rgba($black,.1);padding-bottom:2px;margin-bottom:6px;
    text-transform:uppercase;letter-spacing:1px;width:95%;
  }

  .emailLi{display:inline-block;margin:20px 0;padding-left:10px;border-left:2px solid $color1;width:280px;}
  p{color:$color2;}
  a{color:lighten($color2, 30%);}
  p.reg{font-size:15px;margin-bottom:3px;}
  p.cat{margin:0;line-height:1.3em;font-size:13px;letter-spacing:1px;}
  span.name{font-weight:bold;}

  @include bp(lg){.emailLi:nth-child(2),.emailLi:nth-child(4){float:right;}}

  @include bp(og){width:95%;max-width:400px !important;
    .emailLi{@include sec-default();margin:13px 0;}
    p.reg{line-height:1.5em;}
    p.cat{font-size:12px;}
  }
}

.contact{margin-top:10px;
  li:first-child{margin-right:10px;}
  a{position:relative;z-index:0;text-align:center;width:100%;padding:5px 5px 0;
    &::before{@include before();background-color:$white;}
  }
  svg{color:$color1 !important;width:17px;@include center-block();}
}