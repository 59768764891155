// background: linear-gradient(to bottom left, cyan 50%, palegoldenrod 50%);
@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.Contact{@include sec-default();

  p.cat{line-height:1.4em;font-weight:900;letter-spacing:3px;font-size:.85em;font-family:$heading;color:$color2;}
  @include bp(br){p.cat{font-size:13px;}}
  @include bp(og){p.cat{letter-spacing:1px;}}

  .contactIntro{@include sec-default();position:relative;z-index:0;padding-bottom:100px;
    &::before{@include after();width:50%;left:2.5%;background-color:$color1-lt2;height:90%;bottom:0;}

    .introImg{width:70%;float:right;margin-right:5%;position:relative;z-index:1;}
    .mobImg{display:none;}
    .cbox1{width:55%;position:absolute;margin-top:16%;left:2.3%;padding:30px 0 20px 2%;border-left:4px solid $color1;}
    .cbox2{position:absolute;right:1%;z-index:2;background-color:$color1;width:50%;max-width:380px;
      bottom:35px;padding:30px 50px 15px 35px;   //padding:30px 50px 15px 50px;bottom:50px;   
      p,svg{color:$white;}
      .col{margin:7px 0;}
    }

    h1{font-weight:bold;font-family:$heading;font-size:40px;margin-bottom:30px;}
    h2{font-size:22px;line-height:1.5em;}
    .cboxRow.last,.col.last{margin:0;width:40%;
      p{line-height:1.2em;}
    }

    .cboxBtns{position:absolute;right:-10px;
      svg{color:darken($color1,7%);margin-left:7px;}
    }
    .mdLinkRev,.mdLink2,.mdLink2Rev{font-size:14px;padding:8px 20px;font-weight:bold;}
    .mdLinkRev{color:darken($color1,7%);border-color:lighten($color1,15%);}


    @include bp(tl){padding-bottom:80px;
      h1{font-size:30px;}
      h2{font-size:19px;}

      .cbox2{padding:25px 30px 5px 30px;max-width:360px;}
    }
    @include bp(tw){padding-bottom:60px;
      h1{font-size:28px;}
      h2{font-size:18px;}

      .XXcbox2{bottom:20px;max-width:330px;}
      .XXcboxBtns{bottom:-15px;right:-6px;}
      

      .cbox2{bottom:20px;}
      .cboxBtns{right:-6px;
        svg{width:18px;height:18px;margin-top:-2px;margin-left:5px;}
      }
      .mdLinkRev,.mdLink2,.mdLink2Rev{font-size:13px;}
    }
    @include bp(tb){padding-bottom:70px;
      // .cbox2{bottom:-50px;max-width:330px;}
      .cbox2{bottom:-50px;}
    }


    @include bp(br){padding-bottom:0px;
      &::before{width:94%;height:93%;border-left:3px solid $color1;}
      
      h1{font-size:25px;margin-bottom:5px;}
      h2{font-size:17px;}
      p:not(.cat){font-size:16px;}

      .introImg{position:absolute;width:53%;right:0;margin-right:1.5%;}
      .cbox1{position:relative;left:auto;margin-left:2.3%;border-left:0;width:90%;max-width:100%;margin-top:2.75%;padding:25px 0 5px 20px;
        br{display:none;}
      }
      .cbox2{bottom:auto;right:auto;position:relative;width:95%;max-width:400px;@include center-block();padding:0 0 30px;
        background-color:transparent;float:left;margin-left:5%;

        .first{@include sec-default();}
        .col,.last{display:inline-block;width:auto;margin:7px 0;}
        .col{float:left;margin-right:30px;}
        p{color:$color2;}
      }
      .cboxBtns{position:relative;bottom:auto;right:auto;@include sec-default();margin:0 !important;}
      .mdLinkRev,.mdLink2,.mdLink2Rev{padding:7px 15px;font-size:13px;}
    }

    @media (max-width:940px){.introImg{width:48%;}}

    @include bp(tn){
      h1{font-size:22px;}
      h2{font-size:16px;}
    }

    @include bp(og){.introImg{width:40%;}}

    @include bp(oc){text-align:center;padding-top:5px;
      &::before{width:96%;height:100%;left:2%;}
      h1{font-size:20px;margin-bottom:5px;}

      .introImg{display:none;}
      .cbox1,.cbox2{width:100%;@include center-block();}
      .cbox1{padding:0;}
      .cboxBtns{text-align:center;float:none !important;}
    }
    @include bp(fi) {
      .cbox2 .col{float:none;margin:0 15px;}
      .cboxRow.last{width:100%;float:none;margin:0;
        br{display:none;}
        svg{margin-top:-2px;}
      }
    }
  }


  .contactBodyBg{padding:120px 0 100px;position:relative;z-index:0;
    .medDef{max-width:800px;}

    .cbInner{@include sec-default();padding:10px 0 80px;position:relative;
      &::before,&::after{@include abs();width:55%;background-color:$color2-lt2;}
      &::before{left:2%;height:80%;top:-7%;}
      &::after{right:2%;height:77%;bottom:2%;}
    }

    // .imgMobileSec{display:none;}
    .irImg{position:absolute;width:45%;left:25%;top:60px; top:45px; }
    .left{width:45%;margin-left:-23%;text-align:right;max-width:360px;}
    .right{width:50%;margin-right:-22%;margin-top:110px;max-width:410px;}


    .cbItem{@include sec-default();margin:20px 0 10px;}
    p,a{font-family:$heading;}
    p{margin-bottom:5px;}
    span.name{font-size:1.1em;font-weight:bold;}
    p a{color:inherit;}
    .btns{
      a:first-child{margin-right:10px;}
      a{font-size:13px;}
    }


    @media (min-width:1201px){
      .medDef{max-width:850px;}
      .left{margin-left:-20%;margin-top:-30px;}
      .right{margin-right:-20%;margin-top:230px;}
    }
    @media (min-width:1345px){
      .cbInner{
        &::before{height:73%;}
        &::after{height:75%;bottom:-3%;}
      }
      .medDef{max-width:900px;}
      .irImg{width:50%;left:22%;top:30px;}
      .left{margin-left:-25%;}
      .right{margin-right:-24%;}
    }

    @media (min-width:1650px){padding:150px 0 120px;}

    @include bp(tw){padding:70px 0 160px;
      .medDef{max-width:730px;}
      .cbInner{padding:0px;
        &::before{top:-7%;height:100%;   top:-4%}
        &::after{height:87%;bottom:-12%;  bottom:-10%;}
      }

      .left{margin-left:-21%;margin-top:-15px;}
      .right{width:47%;margin-right:-20%;margin-top:130px;}
      .irImg{width:43%;left:26.5%;top:20px;}


      .btns{position: absolute;bottom:-120px;left:0;text-align:center;
        br,.clearfixBtn{display:none;}
        a{margin:10px;}
      }
      span.name{font-size:17px;}
      p a{font-size:16px;}
    }
    
    @include bp(br){padding:0 0 30px 0;
      .medDef{max-width:700px;}//max-width:600px;float:left;}
      .cbInner{padding:0px;
        &::before,&::after{display:none;}
      }
      .irImg{display:none;}
      .left,.right{@include sec-default();max-width:100%;margin:0;text-align:center;}

      .btns{position:relative;bottom:auto;left:auto;margin-top:20px;
        .clearfix,br{display:none;}
        a{margin:10px;}
      }
      span.name{color:$color1;}
      p a{font-size:15px;}
      p{margin-bottom:2px;}
    }
    @include bp(fi){padding-bottom:0px;
      .btns{margin-top:0;//position:relative;bottom:auto;left:auto;margin-top:20px;
        a{width:100%;float:none;display:block;margin:10px auto;max-width:200px;}
        a:first-child{margin:10px auto;}
      }
    }
  }


  p.rotitle2{@include fonty(50px);font-weight:900;line-height:1em;color:$color1;margin:0;
    position:absolute;width:105px;z-index:-1;letter-spacing:10px;@include rotate(-90deg);left:-12px;bottom:15%;//opacity:.13;
    color:#F9EEE8;
  }
  // @media (min-width:12px){p.rotitle2{position:absolute;opacity:.13;width:105px;z-index:-1;letter-spacing:10px;@include rotate(-90deg);left:-12px;bottom:15%;}}
  @media (max-width:1650px){p.rotitle2{bottom:10%;}}
  @media (max-width:1450px){p.rotitle2{bottom:14%;}}
  @include bp(tw){p.rotitle2{left:-20px;font-size:40px;bottom:10%;}}//opacity:.1;}}
  // bottom:auto;font-size:20px;margin:-30px 0 50px 5%;opacity: .5;color:$color2;border-bottom:1px solid rgba($color2, .1);padding-bottom:3px;width:70%;
  @include bp(br){p.rotitle2{bottom:8%;}}//bottom:20%;opacity:.16;}}
  @include bp(sm){p.rotitle2{bottom:20%;font-size:30px;left:-30px;}}



  .mapRow{@include sec-default();position:relative;z-index:0;padding:60px 0;//margin-bottom:70px;
    &::after{@include after();width:90%;right:0;height:140%;bottom:-40%;@include sec-bgimage('./images/mdtcMap2.png');background-size:cover;background-position:right top;}
    .btnRow{max-width:190px;margin-left:5%;}
    .mdLink,.mdLink2,.mdLinkRev{@include sec-default();margin:8px 0;padding:12px 0;}

    @include bp(tb){.btnRow{margin-left:2%;}}
    @include bp(br){padding:30px 0;
      &::after{height:150%;bottom:-50%;background-image:url('../../assets/images/mdtcMap2b.png');}//background-position:center center;}
      .mdLink,.mdLink2,.mdLinkRev{font-size:14px;}
    }
    @include bp(oc){padding:20px 0;
      &::after{width:100%;z-index:-2;height:160%;bottom:-60%;}
      &::before{@include before();background-color:rgba($f7,.7);}
      .btnRow{margin:auto;}
    }
  }
}