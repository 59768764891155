@import '../../assets/global';
@import '../../assets/variables';
@import '../../assets/mixins';


Footer.HomeStyle{position:absolute;bottom:0;background-color:transparent;padding:0;
  .topFooter{display:none;}
}

.Footer.DefaultStyle{@include sec-default();position:relative;z-index:0;
  &::after{@include after();background-color:$color2;height:70%;left:0;}

  .tfWrap{float:left;width:91.5%;}
  .topFooter{@include sec-default();position:relative;background-color:$color1;padding:60px 0 50px;}
  .topFooter .med2Def{max-width:1000px;}
  .tfwRow{display:flex;margin-left:0;justify-content:space-between;}


  .ftContact{max-width:420px;}
  .col2{max-width:187px;
    p.il{display:inline-block;vertical-align:top;}
    ul{vertical-align:top;margin-top:-4px;float:right;}
    li svg{width:18px;height:18px;}
    li:first-child svg{width:20px;height:20px;}
    // li:last-child svg{width:19px;height:19px;}
  }
  
  p,li,a,svg{color:$white;}
  .ftContact p,.col2 p{font-size:16px;}
  .col2 p{line-height:1.4em;}
  p.license{font-weight:bold;line-height:1.4em;font-size:15px;margin-bottom:8px;}
  button{padding:0;}

  .btmFooter{padding:20px 0;}
  .ftMenu{max-width:810px; margin-left:-12%;
    .navItem,.dropLi{display:inline-block;padding:0 15px;font-size:14px;font-family:$heading;position:relative;z-index:0;
      &::after{@include abs();width:1px;height:15px;right:0;top:50%;margin-top:-7.5px;background:rgba($white,.25);}
      &.withDrop{padding:0;}
    }
    .navItem:first-child{padding-left:0;}
    .navItem:last-child{padding-right:0;
      &::after{display:none;}
    }
    .parentLink{display:none;}
  }
  p.copy{font-size:13px;font-weight:300;line-height:1.5em;margin:-5px 0 0;}

  .eoe{@include sec-default();background-color:$white;padding:30px 0;
    p{font-size:14px;color:$color2;line-height:1.3em;width:75%;  width:78%; margin:0 auto 0 10%;}//max-width:850px;@include center-block();}
  }
  
  
  @media (min-width:1780px){.eoe p{width:70%;margin-left:14%;  width:79%;max-width:1288px;}}
  
  @media (min-width:1201px){.btmFooter .right{position:absolute;left:71%;}}
  @include bp(tl){.ftMenu{margin-left:-5%;}}
  @include bp(tx){.eoe p{margin-left:8%;}}

  @include bp(tw){
    .tfWrap{width:95%;}
    .tfwRow, p.cat{margin-left:-10px;}
    .topFooter .med2Def{width:94%;}
    
    .col2{margin-left:0;float:right;
      ul{display:block;}
    }
    
    .btmFooter{text-align:center;padding:5px 0 10px;
      .medDef{width:95%;float:left;}
      .ftcol1,.right{@include sec-default();margin-top:10px;}
    }
    .ftMenu{margin-left:0;max-width:100%;
      li{text-align: center;}
    }
    p.copy{margin-top:0; br{display:none;}}

    .eoe{text-align:center;p{width:95%;max-width:850px;margin-left:auto;}}
  }
  @include bp(br){
    .topFooter .med2Def{max-width:640px;}
    .tfwRow{display:block;}
    .cred{width:700px;margin-left:-25px;margin-top:15px;text-align:center;}
    p.license{display:inline-block;width:auto;
      &:first-child{float:left;position:relative;z-index:0;}
      &:last-child{float:right;}
      &:first-child::after{@include abs();width:4px;height:4px;right:-22px;top:41%;border-radius:50%;background-color:rgba($color1-lt,.7);}
      br{display:none;}
    }
   
    .btmFooter{padding:0;
      .medDef{@include center-block();max-width:100%; width:90%;}
      .ftcol1{display:none;}
      .right{margin:5px 0;}
    }
  }
  
  @include bp(md){.cred{margin-left:-20px;}}
  @include bp(og){text-align:center;
    .tfWrap{@include center-block();width:85%;}
    .tfwRow,p.cat{margin-left:0;}

    .topFooter{padding:15px 0 10px;
      .med2Def{max-width:450px;}
      .ftContact,.col2{@include sec-default();max-width:100%;}
      p.il{margin-bottom:10px;}
      .col2 ul{width:100%;margin:0px 0 12px;}
      p:not(.cat):not(.copy){font-size:15px;}
      p.summ{line-height:1.5em;}
    }
    .topCopy{margin-top:0px;}
    .btmFooter{text-align:center;}

    // &.v3{
      .cred{width:100%;margin:0;}
      p.license{
        &:first-child,&:last-child{@include sec-default();}
        &::after{display:none;}
      }
    // }
  }
  @include bp(oc){margin-top:15px;
    .tfWrap{width:90%;}
    .topFooter .med2Def{width:95%;}
    .topFooter p{font-size:14px !important;}
  }
  @include bp(fi){p.copy br{display:block;}}
}