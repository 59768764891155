// background: linear-gradient(to bottom left, cyan 50%, palegoldenrod 50%);
@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";
 // background-color:rgba(lighten($color2,15%),.4);
  // background-color: #fafafa;
  // rgb(193 212 251)   rgb(82 109 165)

.Home{@include sec-default();position:relative;z-index:0;padding-bottom:60px;
  br.brMob{display:none;}
  @include bp(nn){br.new1{display:none;}}

  @include bp(og){padding-bottom:15px;
    // br.brMob{display:block;}
    br.br1{display:none;}
    br.new1{display:block;}
  }
  @include bp(oc){br.brMob,br.new1{display:none;}}

  // HAS TEMPS *****
  .homeLay{@include sec-default();position:relative;//overflow:hidden;
    p.headline{@include fonty2(23px);line-height:1.5em;margin-bottom:40px;font-weight:bold;}
    p.rotitle,p.rotitle2{position:absolute;@include fonty(80px);font-weight:bold;letter-spacing:10px;line-height:1em;color:$color2-lt2;width:105px;}
    p.rotitle{@include rotate(90deg);right:0;margin:40px 0 0 0;} // may do this as a min-width.
    p.rotitle2{@include rotate(-90deg);left:0;bottom:35%;margin:0;} // may do this as a min-width.

    @include bp(tw){p.headline{font-size:21px;}}
    @include bp(tb){
      p.headline{font-size:20px;margin-bottom:25px;}
      p.rotitle2{bottom:29%;left:-10px;}
    }
    @include bp(br){
      p.headline{font-size:19px;}
      p.rotitle,p.rotitle2{font-size:70px;width:86px;}
      p.rotitle{top:7%;}
      p.rotitle2{bottom:24%;left:-5px;}
    }
    @include bp(og){p.rotitle,p.rotitle2{display:none;}}
  }
  .hlService{padding:80px 0 0;position:relative;z-index:0;min-height:600px;
    &::before{@include before();width:70.5%;background-color:$color2-lt2;}
    &::after{@include after();left:0;height:50px;background-color:#fafafa;}
    p.rotitle{z-index:2;}

    .stackImg,.stackText{display:inline-block;position:relative;}
    .stackImg{width:60%;}
    .stackText{width:35%;z-index:3;}

    .item1{
      .stackImg{float:left;z-index:2;}
      .stackText{float:right;margin-right:-2%;margin-top:100px;margin-bottom:70px;}
    }
    .item2{
      .stackImg{float:right;margin-right:-6%;margin-top:-70px;}// -5 or lower before 1220.
      .stackText{width:31%;float:left;margin-left:5%;margin-top:100px;}
    }

    .btnRow{@include sec-default();margin:10px 0 0;}
    .imgMobile,.imageRow{display:none;}

    h4{@include fonty(20px);font-weight:800;letter-spacing:2px;margin-bottom:7px;}
    p{line-height:1.7em;}
    a{font-size:13px;}
    button{color:$black;}


    @include bp(fk){&::before{width:67%;}}

    @include bp(tw){padding-top:60px;
      .item1 .stackText{margin-right:0;}
      .item2{
        .stackImg{margin-right:-1%;}
        .stackText{margin-left:2%;width:33%;margin-top:60px;}
      }      
    }
    @include bp(tb){
      &::after{height:30px;}
      .item1 .stackText{margin-top:80px;}
      .item2 .stackImg{margin-right:0;}
      h4{font-size:19px;}
      p{font-size:16px;}
    }
    @include bp(br){padding-top:40px;
      p.headline{@include center-block();text-align: center;}
      .medDef{max-width:700px;}
      .stackImg{width:55%;margin-left:-1%;}
      .item1 .stackText{width:43%;margin-top:60px;margin-right:-2%;margin-bottom:20px;}
      .item2 .stackImg{margin-top:60px;}
      .item2 .stackText{width:43%;margin-left:0;margin-top:0;}

      h4{font-size:18px;}
    }
    @include bp(og){padding-top:30px;
      &::before{width:100%;height:90%;}
      .medDef{width:90%;max-width:550px;}
      // &::after{height:30px;}
      .serveItem, .item1 .stackText, .item2 .stackText{@include sec-default();margin:0 0 10px;}
      .stackImg{display:none;}
      .item1{margin-bottom:40px;}

      h4 br{display:none;}

      .imageRow{@include sec-default();text-align:center;padding:30px 5px 0;
        .item{display:inline-block;width:49%;margin:0 .5%;}
        // iframe{width:600px;height:338px;}  // KPKP AFTER dbox move - uncomment for new vid
      }
    }

    // @include bp(oc){.imageRow iframe{width:400px;height:225px;}}  // KPKP AFTER dbox move - uncomment for new vid
    // @include bp(sm){.imageRow iframe{width:95%;height:200px;}}  // KPKP AFTER dbox move - uncomment for new vid
  }

  // SPACING AND IMAGES AT OG
  .hlAbout{position:relative;z-index:0;background-color:#fafafa;padding:130px 0;margin-bottom:60px;
    .right{margin-top:100px;position:relative;z-index:1;//margin-right:-2%;
      &::after{@include after();width:90%;height:125%;right:-20%;bottom:-10%;border:2px solid $color2-lt;z-index:-2;}
    }
    .clInner{padding:30px 0;background-color:#fafafa;}
    .left{margin-left:-5%;}
    p{margin-bottom:20px;}
    a{font-size:14px;}

    @include bp(tx){
      .right{margin-right:0;
        &::after{right:-14%;}
      }
      .left{margin-left:0;}
    }
    @include bp(tw){//padding-bottom:120px;
      .right{width:37%;margin-right:1%;margin-top:70px;
        &::after{right:-10%;width:85%;}
      }
      .left{width:61%;margin-left:-1%;}
    }

    @include bp(tb){padding:80px 0;
      .right::after{right:-7%;}
    }
    @include bp(br){padding:50px 0;margin-bottom:20px;
      .right{margin-right:auto;margin-top:0;
        &::after{bottom:-20%;display:none;}
      }
      .left{margin-left:0;}
      .clInner{padding:0;margin-bottom:30px;}
    }

    @include bp(og){padding:30px 0;}
  }

  .hlCareer{padding:0px 0 80px;margin-bottom:30px;position:relative;z-index:0;
    &::before{@include abs();width:22%;height:80%;bottom:0;left:39%;background-color:rgba(lighten($color2,20%),.2);}//height:100%;}
    .stackImg,.stackText{display:inline-block;vertical-align:top;}
    .stackImg{float:none;width:48%;margin-left:3%;}
    .stackText{float:none;width:37%;margin-top:25%;margin-right:3%;text-align:right;
      width:38%;
    }
    .linkRow{@include sec-default();margin-top:20px;}





    p.sig,.textLink{@include fonty(21px);font-weight:bold;letter-spacing:2px;}
    p.sig{margin-bottom:30px;}
    .textLink{font-size:16px;}
    a:first-child{margin-right:20px;}

    @include bp(tl){&::before{width:35%;left:32.5%;}}
    @include bp(br){padding:15px 0 60px;margin-bottom:15px;
      &::before{width:50%;left:25%;}
      .stackImg{width:52%;margin-left:2%;}
      .stackText{margin-right:2%;}
      p.sig{font-size:19px;}
    }
    @include bp(md){padding:30px 0 60px;margin-bottom:0;
      &::before{width:52%;left:23%;}
      .stackImg{width:50%;float:right;margin-left:0;margin-right:-1%;}
      .stackText{width:46%;float:left;margin-right:0;margin-left:-2%;}
    }

    @include bp(og){padding:10px 0 30px;text-align:center;
      &::before{display:none;}
      .med2Def{max-width:500px;}
      .stackImg{display:none;}
      .stackText{width:100%;margin:0 auto;text-align: center;}
      p.sig{font-size:18px;letter-spacing:1px;margin-bottom:10px;}
      .linkRow{margin-top:10px;}
      .mdLink2Rev{background-color:$color2;color:$white;}
    }
  }
}