@import '../../assets/global';
@import '../../assets/variables';
@import '../../assets/mixins';

.Header{@include sec-default();margin-bottom:20px;position:relative;z-index:1;
  .inside.hdWrap{width:95%;}
  
  .navMm{@include sec-default();background-color:transparent;padding:20px 0;text-align:center;}
  .logoBlk,.navBarCustom,.navItem,.extraLinks{display:inline-block;vertical-align:top;}
  .phoneMobile{display:none;}
  .logoBlk{float:left;}
  .logoLink{display:block;max-width:220px;}
  // .logoLink.white{display:none;}
  &.DefaultStyle .logoLink.white{display:none;}

  .extraLinks{float:right;margin-top:10px;}
  .elLink{font-size:16px;line-height:1.3em;color:$color1;font-weight:bold;
    span{font-size:14px;}
  }

  .navItem{margin:20px 5px;padding:0 15px;position:relative;z-index:0;
    &::after{@include abs();width:5px;height:5px;right:-7px;top:41%;border-radius:50%;background-color:rgba($color1,.25);}
    &:last-child::after{display:none;}
    &:nth-child(2){margin-left:0;}
    &:last-child{margin-right:0;}
    &.homeLink{display:none;}

    &, a{color:$color2;height:auto;min-height:auto;font-family:$heading;font-size:16px;letter-spacing:.3px;font-weight:500;text-transform:uppercase;}
    a{padding:0;}
    &:hover > a,a:hover{color:$color2;opacity:.7;}
    .parentIcon{width:14px;}
  }
  .navItem.mob,.dropLi.mob{display:none;}
  .custDrop{text-align:left;width:210px;padding:0;z-index:999;margin-top:10px;background-color:$color1-lt;border-left:2px solid $color1;
    li{padding:7px 15px;}
  }

  .mobNav{display:none;float:right;}
  .mobileNavBtn{@include sec-default();width:26px;padding:0;
    span{@include sec-default();height:2px;margin:2.5px 0;background-color:$color2;}
    p,.drawerText{display:none;}
  }  

  @include bp(tl){.inside.hdWrap{width:98%;}}
  @include bp(tw){
    .inside.hdWrap{width:90%;}
    .navMm{padding:15px 0 0;}
    .navBarCustom,.extraLinks{@include sec-default();}
    .logoBlk{position:absolute;width:100%;text-align:center;left:0;}
    .logoLink{@include center-block();}
    .navBarCustom{margin-top:50px;}

    .elLink{display:none;}
    .phoneMobile{@include sec-default();background-color:$color1;text-align:center;font-size:15px;color:$white;}
  }
  @include bp(tb){margin-bottom:0px;}
  @include bp(br){
    .inside.hdWrap{width:95%;}
    .logoLink{width:200px;}
    .navItem{margin:10px 5px;padding:0 10px;}
  }
  @include bp(og){margin-bottom:30px;
    .inside.hdWrap{width:90%;}
    .navBarCustom{display:none;}
    .mobNav{display:inline-block;}
    .extraLinks{margin-top:10px;}  
  }
  @include bp(oc){
    .phoneMobile{@include sec-default();background-color:$color1;text-align:center;font-size:15px;color:$white;}
  }


  &.HomeStyle{position:absolute;z-index:3;
    a,.elLink{color:$white;text-shadow:0px 1px 2px rgba($black, .3);}
    .logoLink.reg{display:none;}
    .logoLink.white{position:relative;
      &::before{@include before();width:150%;left:-35%;height:160%;top:-25%;background:radial-gradient(rgba($color2,.3) 0%, transparent 70%);}
    }

    .navItem::after{background-color:rgba($white,.5);}
    .mobileNavBtn span{background-color:$white;}
    li:hover a,a:hover{color:$white;}
    .custDrop{background-color:$color2;border-color:$white;}
    
    @media (min-width:993px){background-color:rgba($color2,.2);}
    @media (min-width:1201px){background-color:rgba($color2,.0);
      &:hover{background-color:rgba($color2,.2) !important;transition:background-color 1s;}
    }
    @media (min-width:2430px){.hdWrap{max-width:2000px;}}
  }
}

.drawerRoot,.navDrawer{border:none;}
.SideNavList{padding:40px 20px 0;
  .SideNav{margin-top:10%;}
  .closeIcon {color:$white;position:absolute;right:10px;top:15px;cursor:pointer;font-weight:normal;
    svg{width:25px;height:25px;}
  }

  .navItem,.dropLi{@include sec-default();line-height:1em;margin:15px 0;
    &.mob{display:block;}
    &.fw{display:none;}

    &.withDrop{margin:0;
      .parentLink{display:none;}
    }

    a, button{font-weight:400;color:$white;text-transform:none;font-size:19px;line-height:1em;padding:0;
      &:hover{opacity:.7;color:$white;}
    }
  }
  .custDrop{@include sec-default();margin-top:0;background-color:transparent;padding:0;position:relative;box-shadow:none;}

  .sideContact{@include sec-default();border-top:1px solid rgba($white, .2);margin-top:30px;padding-top:30px;
    .scItem{@include sec-default();margin-bottom:15px;}
    p,a,svg{color:$white;font-size:16px;}
  }

  @media (max-width:350px){
    .sideContact{
      p,a,svg{font-size:15px;}
    }
  }
}
