@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.FormPages{@include sec-default();//padding-bottom:60px;
  .fpIntro{@include sec-default();margin:30px 0;text-align:center;
    a{color:$color2l;}
  }
  h1{font-size:30px;font-weight:bold;color:$color1;font-family:$heading;}


  .fpBody{@include sec-default();position:relative;padding:40px 0 60px;}
  .formDrops{position:relative;}
  .formCat{@include sec-default();position:relative;margin:0;padding:20px 0 30px;}
  .accBody{position:relative;z-index:0;padding-bottom:40px;
    &::after{@include after();height:45%;left:0;background:linear-gradient(180deg, rgba($white,0) 2%, $white 100%);}
  }

  .bg1 .accBody{background:linear-gradient(160deg, $white 40%, $color1 100%);}
  .bg2 .accBody{background:linear-gradient(155deg, $white 40%, $color2 100%);}
  .bg1short .accBody{background:linear-gradient(170deg, $white 40%, $color1 100%);
    &::after{height:60%;}
  }

  p.rotitle{@include fonty(50px);font-weight:bold;letter-spacing:10px;line-height:1em;color:$color2-lt2;
    position:absolute;width:75px;left:0;margin:0;top:60%;@include rotate(-90deg);
  }
  .longrt p.rotitle{width:125px;}

  .note{@include sec-default();margin-bottom:30px;
    p{font-size:16px;font-weight:500;width:97%;}//color:$color2l;}
    span.ukcon{padding:0 7px;}
    svg{height:15px;}
    span.red{color:$red;font-size:20px;font-weight:bold;padding:0 5px;}
  }
  .formGroup{float:left;}
  .fgFull{width:100%;}
  
  form,input,p{font-family:$heading;}
  .accTitle{font-size:20px;font-weight:bold;width:100%;text-align:left;border-bottom:$border;padding:0 0 5px;
    text-transform:uppercase;letter-spacing:2px;color:$color1;
    max-width:1080px;@include center-block(); // *** IF WHOLE PAGE ISN'T WRAPPED IN MEDDEF
  }
  p.accTitle{margin-bottom:30px;}
  label{font-weight:500;position:relative;}
  label.required{padding-right:10px;
    &::after{@include abs();content:"*";right:0;color:$red;margin-top:1px;z-index:1;}
  }
  label,span{font-size:15px;}
  input:not([type="radio"]),textarea{width:100%;margin-bottom:30px;border:none;border-bottom:2px solid rgba($black,.1);
    font-size:14px;font-family:$body;line-height:1.5em;background:$fa;color:$gray2;
    &:focus-visible{outline:none;border-color:rgba($color2l,.7);}
  }
  input:not([type="radio"]):not([type="checkbox"]){min-height:40px;padding-left:7px;}
  textarea{min-height:138px;padding:7px;}

  .multiRadio{@include sec-default();margin-bottom:25px;}
  .radioWrap{@include sec-default();margin-bottom:5px;
    label{margin-right:15px;width:auto;display:inline-block;margin-top:0;}
    input,span{vertical-align:top;}
    input{margin-right:0;margin-top:7px;}
    span{margin-right:15px;}
  }
  .checkWrap{@include sec-default();
    input,label{display:inline-block;}
    input{float:left;max-width:30px;margin-top:10px;}
    label{float:right;width:calc(100% - 40px);}
    label.required{padding:0 0px 0 10px;
      &::after{left:0;top:0;}
    }
  }

  p.hdline{font-size:19px;font-weight:bold;color:lighten($color2,20%);text-decoration:underline;margin-top:10px;}
  p.instr{margin-bottom:30px;}
  .formBlock{@include sec-default();border-left:3px solid rgba($color1,.3);padding-left:15px;margin-bottom:50px;
    .formGroup:last-child input:last-child,.formGroup:last-child textarea:last-child{margin-bottom:10px;}
  }
  .mdLink{background-color:$color2l;float:right;}



  .successSub{position:absolute;width:100%;left:0;height:100%;padding:100px;background:$color2-lt2;text-align:center;
    p{font-size:20px;font-weight:bold; width:90%;max-width:680px;@include center-block();}
  }


  @media (min-width:768px){
    .fg2,.fg3,.fg4,.fg2b{display:inline-block;}
    .fg2{width:47%;}
    .fg2+.fg2, .fg4+.fg2, .right{float:right;}
    .fg3{width:31%;}
    .fg3+.fg3,.fg4+.fg4{margin-left:3.5%;}
    .fg4{width:22%;}
    .fg2b{width:65%;}
  }

  @include bp(tl){
    p.rotitle{font-size:40px;width:65px;}
    .longrt p.rotitle{width:100px;}
  }
  @include bp(tx){p.rotitle{display:none;}}
  
  @include bp(tw){
    // .accBody .medDef, 
    .accTitle{width:90%;}
  }

  @include bp(og){
    .fpIntro{margin:20px 0 0;}
    h1{font-size:25px;}

    .fpBody{padding:20px 0 40px;}
    .accBody{padding-bottom:0px;}
    .formCat{padding-top:10px;}
    .formGroup{width:100%;}

    .accTitle{font-size:18px;}
    p.hdline{font-size:17px;}
    .note p{font-size:15px;}
    .mdLink{font-size:14px;}
  }
}

.req{
  .mainfWrap{max-width:1200px;}
  .formGroup{width:32%;float:left;}
  .email{float:right;}
  .phone{margin-left:2%;}

  @include bp(br){
    .mainfWrap{max-width:500px;}
    .formGroup{width:100%;}
    .phone{margin-left:0;}
  }
}