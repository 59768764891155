@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.ImageGridList{position:relative;z-index:0;@include sec-default();
  .serveList{@include sec-default();}
  .serveListMobile{display:none;}

  .igLi{display:inline-block;position:relative;z-index:0;vertical-align:top;margin-bottom:70px;width:50%;
    &.rev{margin-left:-5%;margin-top:130px;text-align:center;}
    // &.reg{float:auto;}
    &.tall{width:45%;}
    &.long{width:60%;}

    &.liFull{width:70%;}
    &.liSolo{width:45%;margin-top:-50px;z-index:1;}
    &.liQuote{width:45%;margin-top:80px;margin-bottom:100px;padding:130px 0;//text-align:center;
      &::after{@include after();width:140%;right:-8%;@include sec-bgimage('./images/prints.jpg');background-size:cover;}
      &::before{@include after();width:140%;right:-8%;background-color:rgba($color1-md,.93);z-index: 0;}
      .lqInner{width:140%;right:0;top:10%;height:100%;position:absolute;z-index:3;border:1.5px solid $color1;}

      .listCapt{padding:0;max-width:350px;@include center-block();}
      .listImg{display:none;}
      
      p{font-size:22px;line-height:1.65em;letter-spacing:0;font-weight:600;text-transform:none;
        &:last-child{margin-top:15px;}
        a{color:$color1;font-size:23px;letter-spacing:1px;}
      }
    }

    p{@include sec-default();margin:0;font-family:$heading;position:relative;z-index:0;}
    p.capt{font-size:21px;text-transform:uppercase;line-height:1.3em;font-weight:bold;letter-spacing:1px;}
    p.sub{font-size:16px;color:$gray2;line-height:1em;}
    .withCap p.sub{padding-top:3px;}
    .noCap p.sub{display:none;}

    @include bp(tl){
      &.liQuote{
        .listCapt{max-width:300px;margin-left:6%;}
        p,p a{font-size:20px;}
      }
    }
    @include bp(tw){
      &.liSolo{width:52%;}
      &.liQuote{width:47%;padding:100px 0;
        &::before,&::after{right:0;}
        .lqInner{right:5%;top:6%;}
        .listCapt{max-width:300px;margin-left:3%;position:relative;z-index:4;}
      }

      p.sub{font-size:15px;line-height:1.3em;}
    }
    @include bp(tb){
      &.liQuote{padding:60px 0;
        .listCapt{max-width:280px;}
        p,p a{font-size:18px;}
      }

      p.sub{font-size:14px;}
    }
    @include bp(br){
      &.liQuote{display:none !important;}
      p.capt{font-size:19px;letter-spacing:.5px;}
    }
    @include bp(og){p.capt{font-size:18px;}}
    @include bp(oc){p.capt{font-size:17px;}}
  }

  .listImg,.listCapt{@include sec-default();position:relative;z-index:0;}
  .listCapt{padding:0 5px;}
  .listImg{margin:15px 0;}
  

  &.withFloat{
    // MOBILE STYLES FOR LI MAY TRANSLATE TO REGULAR W/O FLOAT ITEMS.
    .floatBox{float:left;}
    .floatBox{width:320px;display:inline-block;margin-bottom:50px;//width:300px;
      h4,p{font-family:$heading;}
      h4{font-weight:900;font-size:16.5px;text-transform:uppercase;border-bottom:1px solid rgba($black,.2);letter-spacing:1px;}
      p{font-size:16px;line-height:1.4em;margin-bottom:20px;text-transform:none;}
      p a{color:#2A54C2;font-weight:600;color:$color1;}
    }
    .iglWrap{width:calc(100% - 310px);float:right;padding-left:7%;margin-right:-2%;}

    @include bp(tl){
      .floatBox{margin-left:-2%;} //exterior only??
      .iglWrap{padding-left:2%;margin-right:-2%;} //exterior only??
    }
    @include bp(tw){
      .floatBox{margin-left:0;  width:250px;//exterior only??
        h4{font-size:16px;}
        p{font-size:15px;}
      }

      .iglWrap{width:calc(100% - 265px);}
    }

    @include bp(tb){
      .floatBox{margin-left:-1%;}
      .iglWrap{margin-right:0;}
    }
    @include bp(br){
      // .iglBody{max-width:700px;@include center-block();}
      // .floatBox{margin-left:-2%;}
      // .iglWrap{padding-left:0;margin-right:-2%;}
      // .floatBox h4{font-size:15px;}

      .XXXigLi{width:100% !important;display:block;float:left;margin:0 0 20px !important;padding:65px 0 75px;max-height:210px;overflow:hidden;
        .mobCaption{@include sec-default();background:rgba($white,.85);padding:7px 0;box-shadow:0px 10px 30px rgba($black,.2);}
        p{text-align:center;}
        p.sub{padding:1px 0 4px;color:$color2;}

        .listCapt{width:90%;@include center-block();position:relative;z-index:2;}
        .listImg{position:absolute;z-index:0;bottom:0;
          img{@include sec-default();}
        }
      }

      // 
      .serveListMobile{@include sec-default();}
      .serveList{display:none;}

      .iglBody{max-width:600px;@include center-block();}
      .floatBox{display:none;}
      .iglWrap{@include sec-default();padding:0;margin:0 0 40px;}
      li.liQuote{display:none;}

      .accTitle{@include fonty2(18px);width:100%;text-align:left;font-weight:600;padding-left:0;padding-bottom:6px;}
      .accContent{margin-top:0;padding:10px 0;border-bottom:1px solid rgba($color2,.1);}

      .listImg{margin-top:5px;}
      p.sub{font-size:16px;margin-bottom:5px;}
    }

    @include bp(og){
      .iglBody{max-width:500px;}
      .accTitle{font-size:17px;}
    }

    @include bp(oc){
      .iglBody{width:90%;max-width:500px;}
      .iglWrap{margin-bottom:0px;}
      .XXigLi{padding:0;max-height:100%;margin-bottom:10px !important;
        .mobCaption{background-color:transparent;box-shadow:none;}
        p.sub{padding-bottom:0;}
        .listImg{display:none;}
      }
    }
  }


  .igLi.withBorderR,.igLi.withBorderL,.igLi.withBorderL2{
    .listCapt{position:relative;z-index:2;background-color:$white;}
    .listImg{position:relative;z-index:0;}
    &::after{@include before();width:85%;height:80%;border:2px solid $color1;}//z-index:1;}
  }
  .igLi.withBorderR::after{left:30%;top:30%;}
  .igLi.withBorderL::after{left:-13%;top:-10%;}
  .igLi.withBorderL2::after{left:-10%;top:-8%;}
  // &.withBorderM::after{width:100%;height:85%;left:-50%;top:-7.5%;left:-45%;}


  &.exterior{padding:0 5% 0 8%;
    .floatList{padding-bottom:90px;}
    .serveList li{
      &.li1{width:52%;}
      &.li2{width:45%;z-index:2;}
      &.li3,&.li6,&.li3b,&.li6b,&.li7b{width:42%;}
      &.li4,&.li5b,&.li8b{width:54%;}
      &.li5,&.li1b{width:55%;}

      &.li2,&.li6,&.li2b,&.li6b{margin-top:150px;}
      &.li3{margin-top:-90px;}
      &.li4{margin-top:55px;z-index:2;}

      &.li1b,&.li7b{z-index:2;}
      &.li2b{width:40%;}
      &.li3b,&.li7b{margin-top:-70px;}
      &.li4b,&.li8b{margin-top:120px;}
      &.li4b{width:58%;margin-left:-8%;}
    }

    .li6,.liSolo,.li8b,.li6b{
      .listImg{background-color:#f8f8f8;}
    }


    @media (min-width:1681px){
      .serveList li{
        &.li5b{margin-top:50px;}
        &.li6b{margin-top:190px;}
      }
    }

    @media (max-width:1600px){
      .serveList li{
        p.capt{font-size:20px;}
        &.li1{width:56%;}
        &.li2{width:48%;}
        &.li3,&.li6,&.li3b,&.li6b,&.li7b{width:45%;}
        &.li4,&.li5b,&.li8b{width:58%;}
        &.li5,&.li1b{width:59%;}
        &.liFull{width:75%;}
        &.li2b{width:43%;}
        &.li4b{width:62%;}

        &.withBorder::after{left:23%;top:25%;}
      }
    }

    @include bp(tw){padding:0 3% 0 5%;
      .serveList li{margin-bottom:50px;
        p.capt{font-size:19px;}

        &.li2{margin-top:100px;}
        &.li4{margin-top:20px;}
        &.li6,&.li2b,&.li6b{margin-top:80px;}
        &.li4b,&.li8b{margin-top:40px;}
      }
    }

    @include bp(tb){
      .serveList li{
        &.li6b{margin-top:100px;}
        p.capt{letter-spacing:.5px;font-size:18px;}
      }
    }

    @include bp(br){padding:0 3%;
      .serveList li{
        p.capt{font-size:18px;letter-spacing:0;}
        &.li5,&.li4b{margin-bottom:40px !important;
          .listImg{top:0;}
        }
        &.li3b,&.li4{margin-bottom:10px !important;}
      }
    }

    @include bp(oc){
      .serveList li{
        p.capt{font-size:17px;}
        &.li5,&.li4b,&.li3b,&.li4{margin-bottom:15px !important;}
      }
    }
  }

  &.marine{padding:50px 5% 0 8%;
    &::before{@include before();width:50%;height:25.5%;left:3%;background-color:$color1-lt2;top:-17%;}

    .floatBox{width:170px;
      h4{position:absolute;width:238px;}
    }
    .iglWrap{margin-right:0;padding-left:3%;}

    .serveList li{
      &.li1,&.li3{z-index:3;}
    }

    .li8{margin-top:0px;}
    

    @include bp(big){&::before{top:-14%;height:30%;}}
    @media (min-width:2200px){&::before{width:55%;left:-2%;}}
    @include bp(fk){&::before{width:60%;left:-7%;}}

    @include bp(tl){.iglWrap{padding-left:0;}}
    @include bp(tw){padding-right:1%;
      .iglWrap{padding-left:3%;}
    }
    @include bp(tb){&::before{top:-14%;height:25%;}}
    
    @include bp(br){padding:30px 3% 0 5%;
      // &::before{width:55%;left:1.5%;height:30%;}
      &::before{width:100%;height:200px;left:0;top:-185px;}
    }
    @include bp(oc){padding:10px 0 20px;
      &::before{display:none;}//width:100%;height:350px;left:0;top:-345px;}
    }

  }
}