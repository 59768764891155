@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.TabSection{@include sec-default();
  .tabNav{width:100%;margin-left:0; position:relative;z-index:0;
    &.reg{padding-left:16px;}
    &.center{justify-content:center;}
    
    &::before{left:0;display:none;}
    li{padding:0;margin:0 20px;}
    &.reg li:last-child{margin-right:0;}
    button{padding:0;font-weight:700;@include fonty(24px);line-height:1em;letter-spacing:.5px;
      &:hover{opacity:.8;}
      span{position:relative;}
      span::after{@include after();height:2px;left:0;opacity:.6;}

      b{font-size:14px;font-weight:500;}
    }

    @include bp(tl){button{font-size:23px;}}
    @include bp(tb){button{font-size:21px;}}
    @include bp(br){
      button{font-size:19px;
        b{font-size:13px;}
      }
    }
    @include bp(oc){button{font-size:18px;}}
    // @include bp(og){button{font-size:27px;}}
  }
  .addNavContent{margin-bottom:40px;
    @include bp(br){margin-bottom:30px;
      p{font-size:16px;}
    }
    @include bp(og){margin-bottom:20px;
      p{font-size:15px;}
    }
  }
  

  .tabContent{@include sec-default();margin-top:50px;
    @include bp(tb){margin-top:30px;}
    @include bp(br){margin-top:20px;}
  }
  
  .wfTabHlf{padding-left:0;}
  &.withFloat{position:relative;z-index:0;margin-left:0;
    .tabNav{margin:10px 0 40px;
      button{color:lighten($color2,10%);}
    }

    @include bp(br){.tabNav{margin:5px 0 15px;}}
    @include bp(oc){
      .tabNav{margin-bottom:0px;
        li{margin:5px auto 10px;}
        button{color:darken($color1,5%);}
      }
    }
  }

  &.exterior{
    // &::before{@include before();width:49.5%;height:31%;left:3%;background-color:$color1-lt2;top:-15%;}
    .tabNav{margin-bottom:50px;
      li{margin:0 50px;}
    }

    // @media (min-width:2200px){&::before{width:53%;left:0;}}
    // @include bp(fk){&::before{width:60%;left:-7%;}}
    // @include bp(tl){&::before{width:50%;}}
    // @include bp(tw){&::before{left:2%;width:52%;height:37%;}}
    // @include bp(tb){&::before{height:44%;top:-19%;}}

    @include bp(br){
      // &::before{display:none;}
      // .tabNav{background-color:#fbf2eb;padding-bottom:30px;margin:0 0 30px;}
      .tabContent{margin-top:0;}
    }
    @include bp(og){
      .addNavContent p{font-size:15px;}
    }
    @include bp(oc){
      .tabNav{text-align:center;padding-bottom:20px;
        li{width:100%;}
      }
    }
  }
}